import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonSmall } from "@anwb/poncho/components/button";
import Typography from "@anwb/poncho/components/typography";

import NotificationContext from "../../contexts/NotificationContext";
import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import ProgramTable from "./ProgramTable";

function ProgramsPerProduct() {
  const [programs, setPrograms] = useState<Program[] | undefined>(undefined);
  const { setNotification } = useContext(NotificationContext);

  const { product } = useParams();

  const navigate = useNavigate();

  const { isLoading, setRequest } = useAuthorizedRequest<Program[]>();
  useEffect(() => {
    if (product !== undefined) {
      setRequest({
        path: `/products/${product}/programs`,
        onSuccess: (data) => {
          setPrograms(data);
        },
      });
    } else {
      setNotification({
        title: "Missing product",
        variant: "error",
      });
    }
  }, [setNotification, product, setRequest]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Typography variant="component-title">Programs for product: {product}</Typography>
      <ProgramTable programs={programs || []} />

      <ButtonSmall color="tertiary" onClick={() => navigate(`/products_programs`)}>
        Match programs with products
      </ButtonSmall>
    </>
  );
}

export default ProgramsPerProduct;
