import * as Sentry from "@sentry/react";

if (["prod", "test"].includes(process.env.ENVIRONMENT)) {
  Sentry.init({
    dsn: "https://2ae81da9701d8bcd1622eaf39d759650@o4507644678569984.ingest.de.sentry.io/4508369786699856",
    environment: process.env.ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
