import { useContext, useState } from "react";

import { ButtonSmall } from "@anwb/poncho/components/button";
import Grid from "@anwb/poncho/components/grid";
import Pill, { PillIcon } from "@anwb/poncho/components/pill";
import { Popover } from "@anwb/poncho/components/popover";

import { FiltersContext } from "../../contexts/FiltersContext";
import FilterQueryBuilder from "../../helpers/FilterQueryBuilder";
import { FilterConfiguration } from "../../types/filters";
import Filter from "../Filter/Filter";

function Filters({ filterConfigurations }: { filterConfigurations: FilterConfiguration[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const { filters, setFilters } = useContext(FiltersContext);

  const clearAllFilters = () => {
    setFilters({
      jaargroep: "none",
      "is-open-on-basispoort": "none",
      "start-date": "none",
      status: "none",
      "suspended-in-moodle": "none",
      "product-code": "none",
      "has-basispoort-license-for-streetwise": "none",
      "has-basispoort-license-for-license-management": "none",
      "exists-in-moodle": "none",
      brinvest: "",
      "has-actief-orders": "none",
    });
  };

  return (
    <Popover open={isOpen} onClose={() => setIsOpen(false)} offset={50}>
      <Popover.Trigger>
        <Pill isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <PillIcon type="ui" variant="filters" />
          <Pill.Text>Filters</Pill.Text>
          <Pill.Counter
            count={new FilterQueryBuilder(filters, filterConfigurations).getFilterCount()}
          />
        </Pill>
      </Popover.Trigger>
      <Popover.Header title="Filters">
        <ButtonSmall color="tertiary" onClick={clearAllFilters}>
          Clear filters
        </ButtonSmall>
      </Popover.Header>
      <Popover.Body>
        <Grid columns={Math.min(filterConfigurations.length, 4)}>
          {filterConfigurations.map((filter) => {
            return (
              <Grid.Item key={filter.keyword}>
                <Filter filter={filter} />
              </Grid.Item>
            );
          })}
        </Grid>
      </Popover.Body>
      <Popover.Footer>
        <ButtonSmall color="tertiary" onClick={() => setIsOpen(false)}>
          Close
        </ButtonSmall>
      </Popover.Footer>
    </Popover>
  );
}

export default Filters;
