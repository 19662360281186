import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "@anwb/poncho/components/table";

import NotificationContext from "../../../contexts/NotificationContext";
import { useAuthorizedRequest } from "../../../helpers/api";
import { IBasispoortInstellingDetails } from "../../../types/basispoortDetails";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

function BasispoortDetails() {
  const [basispoortdetails, setBasispoortdetails] = useState<
    IBasispoortInstellingDetails | undefined
  >(undefined);
  const { setNotification } = useContext(NotificationContext);
  const { basispoortId } = useParams();

  const { setRequest, isLoading } = useAuthorizedRequest<IBasispoortInstellingDetails>();
  useEffect(() => {
    if (basispoortId !== undefined) {
      setRequest({
        path: `/basispoort/instelling/${basispoortId}/details`,
        onSuccess: (data) => {
          setBasispoortdetails(data);
        },
      });
    } else {
      setNotification({
        title: "Missing school identifier",
        variant: "error",
      });
    }
  }, [setRequest, setNotification, basispoortId]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Name</Table.HeaderColumn>
          <Table.HeaderColumn>Street</Table.HeaderColumn>
          <Table.HeaderColumn>House number</Table.HeaderColumn>
          <Table.HeaderColumn>House number addition</Table.HeaderColumn>
          <Table.HeaderColumn>Zip code</Table.HeaderColumn>
          <Table.HeaderColumn>Residence</Table.HeaderColumn>
          <Table.HeaderColumn>Brincode</Table.HeaderColumn>
          <Table.HeaderColumn>Dependance code</Table.HeaderColumn>
          <Table.HeaderColumn>Institution ref</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      {basispoortdetails !== undefined && (
        <Table.Body>
          <Table.Row key={basispoortdetails.naam}>
            <Table.Column>{basispoortdetails.naam}</Table.Column>
            <Table.Column>{basispoortdetails.straat}</Table.Column>
            <Table.Column>{basispoortdetails.huisnummer}</Table.Column>
            <Table.Column>{basispoortdetails.huisnummertoevoeging}</Table.Column>
            <Table.Column>{basispoortdetails.postcode}</Table.Column>
            <Table.Column>{basispoortdetails.woonplaats}</Table.Column>
            <Table.Column>{basispoortdetails.brincode}</Table.Column>
            <Table.Column>{basispoortdetails.dependancecode}</Table.Column>
            <Table.Column>{basispoortdetails.instellingRef}</Table.Column>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  );
}

export default BasispoortDetails;
