import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import NotificationContext from "../../../contexts/NotificationContext";
import { useAuthorizedRequest } from "../../../helpers/api";
import { School } from "../../../types/school";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import SchoolTable from "../Tables/SchoolTable/SchoolTable";

function SingleSchool() {
  const [school, setSchool] = useState<School | undefined>(undefined);
  const { setNotification } = useContext(NotificationContext);

  const { setRequest, isLoading } = useAuthorizedRequest<School>();
  const { schoolId } = useParams();

  useEffect(() => {
    if (schoolId !== undefined) {
      setRequest({
        path: `/schools/${schoolId}/view`,
        onSuccess: (data) => {
          setSchool(data);
        },
        onAxiosError: (error) => {
          if (error.response?.status === 404) {
            setNotification({
              title: "School not found",
              variant: "error",
              children: `School ${schoolId} not found in License Office database.`,
            });
          } else {
            setNotification({
              title: "Something went wrong",
              variant: "error",
              children: error.message,
            });
          }
        },
      });
    } else {
      setNotification({
        title: "Missing school identifier",
        variant: "error",
      });
    }
  }, [schoolId, setNotification, setRequest]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return school ? <SchoolTable schools={[school]} /> : "";
}

export default SingleSchool;
