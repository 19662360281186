import { ReactNode, useMemo, useState } from "react";

import { FiltersContext, getCurrentSchoolyearStartDate } from "../contexts/FiltersContext";
import { StoreFilters } from "../types/filters";

export default function FiltersProvider({ children }: { children: ReactNode }) {
  const [filters, setFilters] = useState<StoreFilters>({
    "suspended-in-moodle": "none",
    "is-open-on-basispoort": "none",
    status: "none",
    "product-code": "none",
    "start-date": getCurrentSchoolyearStartDate(),
    "exists-in-moodle": "none",
    "has-basispoort-license-for-streetwise": "none",
    "has-basispoort-license-for-license-management": "none",
    brinvest: "none",
    jaargroep: "none",
    "has-actief-orders": "true",
  });

  // Memoize the context value
  const filtersValue = useMemo(() => ({ filters, setFilters }), [filters]);

  return <FiltersContext.Provider value={filtersValue}>{children}</FiltersContext.Provider>;
}
