import { createContext } from "react";

type EnvironmentContextType = {
  salesforceBaseURL: string | undefined;
  setSalesforceBaseURL: (salesforceBaseURL: string) => void;
  moodleBaseURL: string | undefined;
  setMoodleBaseURL: (moodleBaseURL: string) => void;
};

export const EnvironmentContext = createContext<EnvironmentContextType>({
  salesforceBaseURL: undefined,
  setSalesforceBaseURL: () => {},
  moodleBaseURL: undefined,
  setMoodleBaseURL: () => {},
});

export default EnvironmentContext;
