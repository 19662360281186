import { ChangeEvent, useContext } from "react";

import FormFieldInput from "@anwb/poncho/components/form-field-input";

import { FiltersContext } from "../../contexts/FiltersContext";
import { TextFilterConfiguration } from "../../types/filters";

function TextFilter({ filter }: { filter: TextFilterConfiguration }) {
  const { filters, setFilters } = useContext(FiltersContext);

  const value = filters[filter.keyword] ?? "none";

  const setValue = (newValue: string) => {
    setFilters({ ...filters, ...{ [filter.keyword]: newValue } });
  };

  return (
    <FormFieldInput
      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value.trim() || "none");
      }}
      label={filter.label}
      required
      name={filter.keyword}
      reference={filter.keyword}
      value={value === "none" ? "" : value}
    />
  );
}

export default TextFilter;
