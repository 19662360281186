import { ReactNode, useMemo, useState } from "react";

import { NotificationProps } from "@anwb/poncho/components/notification";

import NotificationContext from "../contexts/NotificationContext";

export default function NotificationProvider({ children }: { children: ReactNode }) {
  const [notification, setNotification] = useState<NotificationProps | undefined>(undefined);

  const notificationValue = useMemo(
    () => ({ notification, setNotification }),
    [notification, setNotification],
  );

  return (
    <NotificationContext.Provider value={notificationValue}>
      {children}
    </NotificationContext.Provider>
  );
}
