import { ReactNode, useMemo, useState } from "react";

import EnvironmentContext from "../contexts/EnvironmentContext";

export default function EnvironmentProvider({ children }: { children: ReactNode }) {
  const [salesforceBaseURL, setSalesforceBaseURL] = useState<string | undefined>(undefined);
  const [moodleBaseURL, setMoodleBaseURL] = useState<string | undefined>(undefined);

  const environmentValue = useMemo(
    () => ({
      salesforceBaseURL,
      setSalesforceBaseURL,
      moodleBaseURL,
      setMoodleBaseURL,
    }),
    [salesforceBaseURL, moodleBaseURL],
  );

  return (
    <EnvironmentContext.Provider value={environmentValue}>{children}</EnvironmentContext.Provider>
  );
}
