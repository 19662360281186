import { createContext } from "react";

import { NotificationProps } from "@anwb/poncho/components/notification";

type NotificationContextType = {
  notification: NotificationProps | undefined;
  setNotification: (notification: NotificationProps | undefined) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  notification: undefined,
  setNotification: () => {},
});

export default NotificationContext;
