import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { AuthenticatedTemplate } from "@azure/msal-react";

import { BreadcrumbContainer } from "@anwb/poncho/components/breadcrumb";
import Container from "@anwb/poncho/components/container";
import { Navigation, NavigationProvider } from "@anwb/poncho/components/navigation";
import { NavigationLogo } from "@anwb/poncho/components/navigation/components/NavigationLogo";
import Notification from "@anwb/poncho/components/notification";
import Spinner from "@anwb/poncho/components/spinner";

import EnvironmentContext from "../../contexts/EnvironmentContext";
import NotificationContext from "../../contexts/NotificationContext";
import { useAuthorizedRequest } from "../../helpers/api";
import { Environment } from "../../types/environment";
import MainNavigation from "../MainNavigation/MainNavigation";
import Profile from "../Profile/Profile";

function PageLayout() {
  const { notification, setNotification } = useContext(NotificationContext);
  const { setSalesforceBaseURL, setMoodleBaseURL } = useContext(EnvironmentContext);

  const [environment, setEnvironment] = useState<Environment>();
  const location = useLocation();
  const navigate = useNavigate();
  const { setRequest } = useAuthorizedRequest<Environment>();

  useEffect(() => {
    // clear possible notification on location change
    setNotification(undefined);
  }, [location, setNotification]);

  useEffect(() => {
    setRequest({
      path: "/environment",
      onSuccess: (data) => {
        setEnvironment(data);
        setSalesforceBaseURL(data.salesforce);
        setMoodleBaseURL(data.moodle);
      },
    });
  }, [setRequest, setMoodleBaseURL, setSalesforceBaseURL]);

  return (
    <>
      <NavigationProvider>
        <Navigation>
          <NavigationLogo
            alt="Logo van de ANWB"
            href="#"
            onClick={() => navigate("/")}
            src="https://static.anwb.nl/poncho/navigation/images/logo.svg"
          />
          <Navigation.Toggle>Menu</Navigation.Toggle>
          <MainNavigation />
          <Navigation.Actions>
            <Navigation.ActionSearch reference="search">{null}</Navigation.ActionSearch>
            <AuthenticatedTemplate>
              <Profile />
            </AuthenticatedTemplate>
          </Navigation.Actions>
          <AuthenticatedTemplate>
            <Navigation.Secondary>
              {environment ? (
                <Navigation.Items>
                  <SecondaryNavigationItem
                    reference="basispoort"
                    label="Basispoort"
                    href={`https://${environment.basispoort}.basispoort.nl`}
                  />
                  <SecondaryNavigationItem
                    reference="salesforce"
                    label="Salesforce"
                    href={environment.salesforce}
                  />
                  <SecondaryNavigationItem
                    reference="moodle"
                    label="Moodle"
                    href={`${environment.moodle}/login/index.php?saml=off`}
                  />
                </Navigation.Items>
              ) : (
                <Spinner size="small" />
              )}
            </Navigation.Secondary>
          </AuthenticatedTemplate>
        </Navigation>
      </NavigationProvider>
      {notification && (
        <Notification variant={notification.variant} title={notification.title} closeButton>
          {notification.children}
        </Notification>
      )}
      <Container withConstrainedWidth>
        <BreadcrumbContainer />
        <Outlet />
      </Container>
    </>
  );
}

function SecondaryNavigationItem({
  reference,
  href,
  label,
}: {
  reference: string;
  label: string;
  href: string;
}) {
  return (
    <Navigation.Item reference={reference}>
      <Navigation.Link color="secondary" target="_blank" href={href}>
        {label}
      </Navigation.Link>
    </Navigation.Item>
  );
}

export default PageLayout;
