import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonSmall } from "@anwb/poncho/components/button";
import Link from "@anwb/poncho/components/link";
import Table from "@anwb/poncho/components/table";

import EnvironmentContext from "../../contexts/EnvironmentContext";
import { Program } from "../../types/program";
import BooleanIcon from "../BooleanIcon/BooleanIcon";

type ProgramTableProps = {
  programs: Program[];
};

function ProgramTable({ programs }: ProgramTableProps) {
  const { moodleBaseURL } = useContext(EnvironmentContext);

  const navigate = useNavigate();

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Name</Table.HeaderColumn>
          <Table.HeaderColumn>Moodle ID</Table.HeaderColumn>
          <Table.HeaderColumn>For teachers only</Table.HeaderColumn>
          <Table.HeaderColumn>Manageable</Table.HeaderColumn>
          <Table.HeaderColumn>Actions</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {programs?.map((program) => {
          return (
            <Table.Row key={program.id}>
              <Table.Column>{program.name}</Table.Column>
              <Table.Column>
                {program.moodleProgramId && moodleBaseURL && (
                  <Link
                    href={`${moodleBaseURL}/admin/tool/program/edit.php?id=${program.moodleProgramId}`}
                    iconInverted={false}
                  >
                    {program.moodleProgramId}
                  </Link>
                )}
              </Table.Column>
              <Table.Column>
                <BooleanIcon isTrue={program.forTeachersOnly} />
              </Table.Column>
              <Table.Column>
                <BooleanIcon isTrue={program.manageable} />
              </Table.Column>
              <Table.Column>
                <ButtonSmall
                  color="tertiary"
                  onClick={() => navigate(`/programs/edit/${program.id}`)}
                >
                  Edit
                </ButtonSmall>
              </Table.Column>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default ProgramTable;
