import { createContext } from "react";

import { StoreFilters } from "../types/filters";

type FiltersContextType = {
  filters: StoreFilters;
  setFilters: (filters: StoreFilters) => void;
};

export const getCurrentSchoolyearStartDate = () => {
  const today = new Date();
  if (today.getMonth() < 7) {
    return `${today.getFullYear() - 1}-08-01`;
  }
  return `${today.getFullYear()}-08-01`;
};

export const FiltersContext = createContext<FiltersContextType>({
  filters: {
    "suspended-in-moodle": "none",
    "is-open-on-basispoort": "none",
    status: "none",
    "product-code": "none",
    "start-date": getCurrentSchoolyearStartDate(),
    "exists-in-moodle": "none",
    "has-basispoort-license-for-streetwise": "none",
    "has-basispoort-license-for-license-management": "none",
    brinvest: "none",
    jaargroep: "none",
    "has-actief-orders": "true",
  },
  setFilters: () => {},
});
