import { useContext, useEffect, useState } from "react";

import {
  AccountInfo,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import axios, { AxiosError, AxiosResponse } from "axios";

import NotificationContext from "../contexts/NotificationContext";

type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;

interface IRequestInfo<T> {
  method?: string;
  path: string;
  data?: JSONValue;
  onSuccess?: (data: T) => void;
  onAxiosError?: (error: AxiosError) => void;
}

interface IResponseInfo<T> {
  response?: AxiosResponse<T>;
  error?: AxiosError;
}

async function getIDToken(
  account: AccountInfo | null,
  instance: IPublicClientApplication,
): Promise<string> {
  const request: SilentRequest = {
    scopes: [`${instance.getConfiguration().auth.clientId}/.default`],
    ...(account !== null && { account }),
  };

  if (account) {
    try {
      const result = await instance.acquireTokenSilent(request);
      return result.idToken;
    } catch (error) {
      if (!(error instanceof InteractionRequiredAuthError)) {
        throw error;
      }
    }
  }
  const result = await instance.acquireTokenPopup(request);
  return result.idToken;
}

export function useAuthorizedRequest<T>(props?: IRequestInfo<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [requestInfo, setRequest] = useState(props);
  const [responseInfo, setResponse] = useState<IResponseInfo<T>>({});

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { setNotification } = useContext(NotificationContext);

  const apiHost = process.env.API_URI;

  useEffect(() => {
    if (requestInfo === undefined) {
      return;
    }

    if (apiHost === undefined) {
      throw new Error("API host is not set");
    }

    const { method, path, data, onSuccess, onAxiosError } = requestInfo;

    getIDToken(account, instance)
      .then((idToken) => {
        axios({
          method,
          url: `${apiHost}${path}`,
          data,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
          .then((response) => {
            setResponse({ response });
            if (onSuccess !== undefined) {
              onSuccess(response.data as T);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            if (error instanceof AxiosError) {
              setResponse({ error });
              setIsLoading(false);
              if (onAxiosError !== undefined) {
                onAxiosError(error);
              } else {
                setNotification({
                  title: "Something went wrong",
                  variant: "error",
                  children: error.message,
                });
              }
            } else {
              throw error;
            }
          });
      })
      .catch((reason) => {
        // eslint-disable-next-line no-console
        console.debug(reason);
      });
    setIsLoading(true);
  }, [requestInfo, account, apiHost, instance, setNotification]);

  return { responseInfo, isLoading, setRequest };
}
